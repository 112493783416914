$(function(){
    console.log('[By shellaidev] It works..');

    // Dropdown
    $(document).on('click', '.dropdown__button', function () {
        let parent = $(this).closest('.dropdown');
        if(parent == null) return;

        let dropdownContent = $(parent).find('.dropdown__content');
        if(dropdownContent == null) return;

        if( $(dropdownContent).is(":visible") ){
            $(dropdownContent).slideUp(200);
            $(this).find('.dropdown__arrow').css({'transform': 'rotate(0deg)'});
        } else {
            $('.dropdown__arrow').css({'transform': 'rotate(0deg)'});
            $('.dropdown__content').hide();
            $(dropdownContent).slideDown(200);
            $(this).find('.dropdown__arrow').css({'transform': 'rotate(180deg)'});
        }
    });

    // On dropdown choosen
    $(document).on('click', '.dropdown__content li', function () {
        let value = $(this).html();
        let closestDropdown = $(this).closest('.dropdown');
        $(closestDropdown).find('.dropdown__item').html(value);
        $(closestDropdown).find('.dropdown__arrow').css({'transform': 'rotate(0deg)'});
        $('.dropdown__content').hide();
    });

    // Mob menu
    $(document).on('click', '.burger', function () {
       let mobMenu = $('.mob-menu');
       if(mobMenu == null) return;

       if( $(mobMenu).is(':visible') ){
            $(this).attr('src', 'assets/images/burger.svg');
            $(mobMenu).slideUp(300);
       } else {
            $(this).attr('src', 'assets/images/burger_close.svg');
            $(mobMenu).slideDown(300);
       }
    });
});